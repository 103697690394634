body {
  margin: 0;
  padding-top: 15px;
  background-color: #f5f5f5;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 2;
}

.popup {
  background: white;
  width: 388px;
  height: 161px;
  padding: 25px;
  margin: 0 auto;
  position: relative;
  top: 145px;
  border-radius: 2%;
}

#wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

#signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

nav {
	margin-bottom: 25px;
}

.link-btn {
  background: transparent;
  border: none;
  color: #007bff;
}

.btn-outline-info {
  margin-left: 10px;
}

.print {
  display: none;
}


@media print {
  body {
    font-size: 21px;
  }

  .print {
    display: block;
  }

  .hide {
    display: none;
  }
}